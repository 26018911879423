<template>
    <div class="screen_bg_0">
        <!-- <bg></bg>
        <page-top></page-top> -->
        <router-view></router-view>
    </div>
</template>

<script>
    // import PageTop from "@/components/pages/admin/screen/top.vue";
    // import Bg from "@/components/pages/admin/screen/bg.vue";
    export default {
        components: {
            // PageTop,
            // Bg
        }
    };
</script>
<style scoped>
    @import url("~@/assets/font/font.css");
    .screen_bg_0 {
        height: 100%;
        overflow: hidden;
    }
</style>
